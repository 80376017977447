export const MENU = [
    {
        titleLocalizationKey: "home",
        key: "/",
        path: "/"
    },
    {
        titleLocalizationKey: "about",
        key: "/about",
        path: "/about"
    },
    {
        titleLocalizationKey: "contact",
        key: "/contact",
        path: "/contact"
    }
];
